
import { useBreakpoints, Svg } from '@bd/components'
import { useUserData } from '@bd/components/use'
import { defineComponent, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const domainURL = process.env.VUE_APP_DOMAIN_URL

export default defineComponent({
  components: { Svg },
  setup() {
    const { t } = useI18n()
    const { mobileSize } = useBreakpoints()
    const { isLogged } = useUserData()
    const router = useRouter()

    watchEffect(() => {
      if (!mobileSize.value || isLogged.value) {
        router.push({ name: 'Dashboard' })
      }
    })

    return {
      t,
      domainURL,
    }
  },
})
