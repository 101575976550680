<template>
  <div class="welcome">
    <div class="logo-container">
      <img :src="require('@bd/assets/images/immoby-logo.svg')" />
    </div>
    <div class="bottom-container">
      <div class="greetings">
        <h1 class="h-1">{{ t('welcome.title') }}</h1>
        <p class="hint">{{ t('welcome.hint') }}</p>
      </div>
      <div class="items">
        <a class="item" :href="`${domainURL}aplikacja`">
          <div class="icon-container">
            <Svg
              class="icon"
              :src="require('@bd/assets/icons/welcome-sell.svg')"
            />
          </div>
          <div class="detail">{{ t('welcome.sell') }}</div>
          <i class="item-append-icon pi pi-chevron-right"></i>
        </a>

        <router-link class="item" :to="{ name: 'Dashboard' }">
          <div class="icon-container">
            <Svg
              class="icon"
              :src="require('@bd/assets/icons/welcome-browse.svg')"
            />
          </div>
          <div class="detail">{{ t('welcome.browse') }}</div>
          <i class="item-append-icon pi pi-chevron-right"></i>
        </router-link>

        <router-link class="item" :to="{ name: 'Login' }">
          <div class="icon-container">
            <Svg
              class="icon"
              :src="require('@bd/assets/icons/welcome-login.svg')"
            />
          </div>
          <div class="detail">{{ t('welcome.login') }}</div>
          <i class="item-append-icon pi pi-chevron-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useBreakpoints, Svg } from '@bd/components'
import { useUserData } from '@bd/components/use'
import { defineComponent, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const domainURL = process.env.VUE_APP_DOMAIN_URL

export default defineComponent({
  components: { Svg },
  setup() {
    const { t } = useI18n()
    const { mobileSize } = useBreakpoints()
    const { isLogged } = useUserData()
    const router = useRouter()

    watchEffect(() => {
      if (!mobileSize.value || isLogged.value) {
        router.push({ name: 'Dashboard' })
      }
    })

    return {
      t,
      domainURL,
    }
  },
})
</script>

<style lang="scss" scoped>
.welcome {
  @include flex($direction: column);
  height: 100vh;
}

.logo-container {
  flex: 1 0 auto;
  display: flex;
}

.hint {
  color: $comet;
}

.greetings {
  margin-top: 4vh;
  margin-bottom: 2vh;
  text-align: center;
  .hint {
    margin-top: 1rem;
  }
}

.bottom-container {
  background-color: $alabaster;
  width: 100%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding-bottom: 6vh;
}

.item {
  @include flex($justify: flex-start);
  padding: 0 1rem;

  &:not(:last-of-type) {
    .detail {
      border-bottom: $divider-border;
    }
  }

  text-decoration: none;
  color: inherit;
}

.icon-container {
  @include flex;
  @include uniform-size(50px);
  background-color: rgba($primary-color, 0.1);
  min-width: 50px;
  border-radius: 16px;
  margin-right: 1rem;
  .icon {
    @include uniform-size(20px);
    color: $primary-color;
  }
}

.detail {
  padding: 2rem 0;
  width: 100%;
}

.item-append-icon {
  color: $primary-color;
}
</style>
